import axios from "axios";
import { ApplicationType } from "Models";

export class ApplicationTypeService {
  static async getAll(): Promise<ApplicationType[]> {
    const response = await axios.get<ApplicationType[]>("/api/ApplicationType");
    return response.data;
  }

  static async insert(applicationType: ApplicationType): Promise<ApplicationType> {
    const response = await axios.post<ApplicationType>("/api/ApplicationType", applicationType);
    return response.data;
  }
}
