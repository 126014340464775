import { User } from "Models";
import { createContext, Dispatch, SetStateAction } from "react";

interface IUserContext {
  accessToken: string | null;
  setAccessToken: Dispatch<SetStateAction<string | null>>;
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}
export const UserContext = createContext<IUserContext>({
  accessToken: null,
  setAccessToken: () => {},
  user: null,
  setUser: () => {},
});
