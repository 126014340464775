import { IconButton, Tooltip } from "@mui/material";
import { Login as LoginIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const LoginButton: React.FC = () => {
	const Navigate = useNavigate();
	const handleLogin = () => {
		Navigate("/Login");
	};

	return (
		<Tooltip title="Login" arrow>
			<IconButton sx={{ color: "white" }} onClick={handleLogin}>
				<LoginIcon />
			</IconButton>
		</Tooltip>
	);
};

export default LoginButton;
