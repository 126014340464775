import { Stack, Box, Paper, Typography, List, ListItem, ListItemButton, Divider } from "@mui/material";
import { Outlet, useNavigate, useMatch } from "react-router-dom";

export const Settings: React.FC = () => {
  const isLocationIndex = useMatch({ path: "/settings", caseSensitive: false, end: true });
  const isLocationAddApplication = useMatch({ path: "/settings/application", caseSensitive: false, end: true });
  const isLocationAddApplicationType = useMatch({ path: "/settings/applicationType", caseSensitive: false, end: true });
  const isLocationManageUsers = useMatch({ path: "/settings/manageUsers", caseSensitive: false, end: false });
  const Navigate = useNavigate();
  return (
    <Stack direction={{ sm: "column", md: "column", lg: "row", xl: "row" }} height={{ lg: "calc(100vh - 64px)", xl: "calc(100vh - 64px)" }}>
      <Paper variant="outlined" square sx={(theme) => ({ minWidth: 240, minHeight: "calc(100vh - 64px)", maxHeight: "calc(100vh - 64px)", [theme.breakpoints.down("lg")]: { minHeight: "0", maxHeight: "0", position: "sticky", top: 0, zIndex: theme.zIndex.drawer + 2 }, overflowY: "auto" })}>
        <Typography sx={{ my: 1, ml: 2 }}>Settings Menu</Typography>
        <Divider />
        <List>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton selected={Boolean(isLocationIndex) || Boolean(isLocationAddApplication)} onClick={() => Navigate("Application")}>
              Add Application
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton selected={Boolean(isLocationAddApplicationType)} onClick={() => Navigate("ApplicationType")}>
              Add Application Type
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton selected={Boolean(isLocationManageUsers)} onClick={() => Navigate("manageUsers")}>
              Manage Users
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
      <Box flexGrow={1} sx={(theme) => ({ minHeight: "calc(100vh - 64px)", maxHeight: "calc(100vh - 64px)", [theme.breakpoints.down("lg")]: { minHeight: "0", maxHeight: "0" }, overflowY: "auto" })}>
        <Outlet />
      </Box>
    </Stack>
  );
};
