import { Typography, Box, Grid, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Avatar, Paper, Divider, Stack } from "@mui/material";
import { useUsers, useLoginsByUserId } from "Hooks";
import { User } from "Models";
import moment from "moment";
import { useState } from "react";

export const ManageUsers: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [users] = useUsers();
  const [selectedUserLogins] = useLoginsByUserId(selectedUser?.id);

  return (
    <Grid container spacing={1} p={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Paper sx={{ height: 400, maxHeight: 400, overflowY: "auto" }}>
          <Paper sx={(theme) => ({ position: "sticky", top: 0, zIndex: theme.zIndex.drawer + 1 })}>
            <Stack direction={"row"} alignItems="baseline">
              <Typography variant="h5" p={1} px={3}>
                Users
              </Typography>
              <Typography color="GrayText" p={1} px={3}>
                All {users.length} users
              </Typography>
            </Stack>
            <Divider sx={{ mx: 2 }} />
          </Paper>
          <List>
            {users.map((user) => (
              <ListItem key={user.id}>
                <ListItemButton selected={user === selectedUser} onClick={() => setSelectedUser(user)}>
                  <ListItemIcon>
                    <Avatar src={user.userProfileImage} />
                  </ListItemIcon>
                  <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.role?.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {selectedUser ? (
          <Paper sx={{ py: 1, height: 400, maxHeight: 400 }}>
            <Box sx={{ height: "100%", overflowY: "auto" }}>
              <Paper sx={(theme) => ({ position: "sticky", top: 0, zIndex: theme.zIndex.drawer + 1 })}>
                <Stack direction={"row"} alignItems="baseline">
                  <Typography variant="h5" p={1} px={3}>
                    User logs
                  </Typography>
                  <Typography color="GrayText" p={1} px={3}>
                    User logged in {selectedUserLogins.length} times
                  </Typography>
                </Stack>
                <Divider sx={{ mx: 2 }} />
              </Paper>
              <List>
                <Grid container>
                  {selectedUserLogins.map((selectedUserLogin) => {
                    console.log(selectedUserLogin);
                    return (
                      <Grid key={selectedUserLogin.id} item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem>
                          <ListItemText primary={moment(new Date(selectedUserLogin.lastLoginDate)).fromNow()} secondary={`from ${selectedUserLogin.ipAddress}`} />
                        </ListItem>
                      </Grid>
                    );
                  })}
                </Grid>
              </List>
            </Box>
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
};
