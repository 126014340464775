import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { getLocalStorage, setAuthToken } from "Helpers";
import { UserContext } from "Contexts";
import axios from "axios";

export const SessionRoute: React.FC = () => {
  const accessTokenStorage = getLocalStorage<string>("accessToken");
  const { setAccessToken, setUser } = useContext(UserContext);

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      console.log(error.response.status);
      if (error.response.status === 401) {
        setAccessToken(null);
        setUser(null);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  setAuthToken(accessTokenStorage);
  if (!accessTokenStorage) {
    setAccessToken(null);
    setUser(null);
  }

  return <Outlet />;
};

export default SessionRoute;
