import { Button, TextField, Typography, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "Contexts";
import { Navigate as NavigateComponent, useLocation, useNavigate } from "react-router-dom";
import { signUp } from "Middleware";
import { setAuthToken } from "Helpers";

export const SignUpPage: React.FC = () => {
  const { accessToken, setAccessToken, setUser } = useContext(UserContext);
  const Navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectTo = searchParams.get("redirectTo");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);

  if (accessToken) {
    return <NavigateComponent to={`/`} replace />;
  }

  const handleOnClickSignUp = async () => {
    setHasError(await signUp(username, email, password, setAuthToken, setAccessToken, setUser, Navigate, redirectTo));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleOnClickSignUp();
    }
  };

  return (
    <Grid container gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
      <Grid item xs={0} sm={0} md={2} lg={3} xl={4}></Grid>
      <Grid container xs={12} sm={12} md={8} lg={6} xl={4} gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          {hasError ? <Typography sx={(theme) => ({ color: theme.palette.error.main })}>Username or password is incorrect!</Typography> : null}
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography>Username</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField value={username} fullWidth onChange={(e) => setUsername(e.target.value)} onKeyDown={handleKeyPress} size="small" placeholder="username" />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField value={email} fullWidth onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyPress} size="small" type="email" placeholder="email" />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography>Password</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField value={password} fullWidth onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyPress} size="small" type="password" placeholder="password" />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button fullWidth disabled={!(username && password && email)} variant="contained" onClick={handleOnClickSignUp}>
            Sign up
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={0} sm={0} md={2} lg={3} xl={4}></Grid>
    </Grid>
  );
};

export default SignUpPage;
