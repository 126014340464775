import React from "react";
import * as Pages from "Pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "Components";
import { ProtectedRoute, SessionRoute, SuperAdminRoute } from "Helpers";

// // Add a response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     console.log(error.response.status);
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route element={<SessionRoute />}>
          <Route index element={<Pages.Applications />} />
          <Route path="Login" element={<Pages.LoginPage />} />
          <Route path="SignUp" element={<Pages.SignUpPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<SuperAdminRoute />}>
              <Route path="settings" element={<Pages.Settings />}>
                <Route index element={<Pages.AddApplication />} />
                <Route path="application" element={<Pages.AddApplication />} />
                <Route path="applicationType" element={<Pages.AddApplicationType />} />
                <Route path="ManageUsers" element={<Pages.ManageUsers />} />
              </Route>
            </Route>
            <Route path="convert" element={<Pages.ConvertToBase64 />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
