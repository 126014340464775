import { ApplicationType } from 'Models';
import { ApplicationTypeService } from 'Services';
import React, { Dispatch, SetStateAction } from 'react';

export const useApplicationTypes = (): [ApplicationType[], Dispatch<SetStateAction<ApplicationType[]>>, boolean] => {
    const [applicationTypes, setApplicationTypes] = React.useState<ApplicationType[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        ApplicationTypeService.getAll()
            .then(applicationTypes => setApplicationTypes(applicationTypes))
            .finally(() => setIsLoading(false));
    }, []);

    return [applicationTypes, setApplicationTypes, isLoading]
}