import { Login } from "Models";
import { LoginService } from "Services";
import React, { Dispatch, SetStateAction } from "react";

export const useLoginsByUserId = (userId?: string | number): [Login[], Dispatch<SetStateAction<Login[]>>, boolean] => {
  const [logins, setLogins] = React.useState<Login[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (userId) {
      LoginService.getAllByUserId(userId)
        .then((logins) => setLogins(logins))
        .finally(() => setIsLoading(false));
    } else {
      setLogins([]);
    }
  }, [userId]);

  return [logins, setLogins, isLoading];
};
