import { Grid, Stack, ListItemText, Paper, Typography, List, ListItemButton, ListItemIcon, Link, CircularProgress, Box } from "@mui/material";
import { UserContext } from "Contexts";
import { useApplicationTypes } from "Hooks";
import { useContext } from "react";

export const Applications: React.FC = () => {
  const [applicationTypes, , isLoading] = useApplicationTypes();
  const { accessToken } = useContext(UserContext);

  return (
    <Grid spacing={2} p={2} container>
      {applicationTypes.length > 0 ? (
        applicationTypes.map((applicationType) => {
          return (
            <Grid xs={12} sm={6} md={4} lg={3} item key={applicationType.id}>
              <>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <img src={applicationType.icon} alt={applicationType.name} height={30} />
                  <Typography sx={{ p: 1 }} variant="h5">
                    {applicationType.name}
                  </Typography>
                </Stack>
                <List dense>
                  {applicationType.applications.map((application) => (
                    <Link key={application.id} href={application.url} target="_blank" sx={{ textDecoration: "none" }}>
                      <Paper sx={{ borderRadius: 2, mb: 2 }}>
                        <ListItemButton sx={{ borderRadius: 2 }}>
                          <ListItemIcon>
                            <img src={application.icon} alt={application.name} height={30} />
                          </ListItemIcon>
                          <ListItemText primary={application.name} secondary={application.description} />
                        </ListItemButton>
                      </Paper>
                    </Link>
                  ))}
                </List>
              </>
            </Grid>
          );
        })
      ) : isLoading ? (
        <Box width="100%" height="calc(100vh - 100px)" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Typography align="center" my={3} width="100%">
          {accessToken ? "-- No Data --" : "You need to be logged in to view the contents of this page!"}
        </Typography>
      )}
    </Grid>
  );
};
