import { useState } from "react";
import { Grid, TextField, Button, Stack, Typography } from "@mui/material";
import { ApplicationTypeService } from "Services";
import { ApplicationType } from "Models";

const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

export const AddApplicationType: React.FC = () => {
  const [newAppType, setNewAppType] = useState<ApplicationType>(new ApplicationType());
  //const [applicationTypes] = useApplicationTypes();

  const handleOnChangeTextField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewAppType((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnChangeImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    getBase64(file)
      .then((icon) => {
        setNewAppType((prevState) => ({ ...prevState, icon }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOnClickCreateNewApplication = () => {
    ApplicationTypeService.insert(newAppType)
      .then((application) => {
        setNewAppType(new ApplicationType());
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid container>
      <Grid xs={0} sm={1} md={2} lg={3} xl={3} item />
      <Grid xs={12} sm={10} md={8} lg={6} xl={6} item>
        <Stack spacing={2} p={2}>
          <Typography variant="body2" color="GrayText" align="center">
            Add new Application Type
          </Typography>
          <TextField onChange={handleOnChangeTextField} size="small" placeholder="Name" name="name" value={newAppType.name} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <input type="file" onChange={handleOnChangeImageUpload} />
            {newAppType.icon && <img src={newAppType.icon} alt="sd" height={30} />}
          </Stack>
          <Button onClick={handleOnClickCreateNewApplication} variant="contained" disabled={!(newAppType.icon && newAppType.name)}>
            Create
          </Button>
        </Stack>
      </Grid>
      <Grid xs={0} sm={1} md={2} lg={3} xl={3} item />
    </Grid>
  );
};
