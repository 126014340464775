export class Base {
    public id: number;
    public createdAt: Date;
    public updatedAt: Date;

    constructor(other?: Partial<Base>) {
        this.id = other?.id ?? 0;
        this.createdAt = other?.createdAt ?? new Date();
        this.updatedAt = other?.updatedAt ?? new Date();
    }
}