import axios, { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Role, User } from "Models";
import { setLocalStorage } from "Helpers";

export const logout = (setAuthToken: (accessToken: string | null) => void, setAccessToken: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User | null>>) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  setAccessToken("");
  setAuthToken(null);
  setUser(null);
  window.location.href = "/";
};

export const login = async (username: string, password: string, setAuthToken: (accessToken: string | null) => void, setAccessToken: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User | null>>, Navigate: NavigateFunction, redirectTo: string | null) => {
  const loginPayload = {
    username,
    password,
  };

  let hasError = false;

  await axios
    .post("/api/user/login", loginPayload)
    .then((response) => {
      setStates(response, setAuthToken, setAccessToken, setUser);
      Navigate(decodeURIComponent(redirectTo ?? "/"));
    })
    .catch((err) => {
      console.error(err);
      hasError = true;
    });

  return hasError;
};

export const signUp = async (username: string, email: string, password: string, setAuthToken: (accessToken: string | null) => void, setAccessToken: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User | null>>, Navigate: NavigateFunction, redirectTo: string | null) => {
  const signUpPayload = {
    username,
    email,
    password,
  };

  let hasError = false;

  await axios
    .post("/api/user/signUp", signUpPayload)
    .then((response) => {
      setStates(response, setAuthToken, setAccessToken, setUser);
      Navigate(decodeURIComponent(redirectTo ?? "/"));
    })
    .catch((err) => {
      console.error(err);
      hasError = true;
    });

  return hasError;
};

export interface JWTObject extends User {
  exp: number;
  iat: number;
  userId: number;
}
export const decodeJwtToken = (token?: string | null) => (token ? jwt_decode<JWTObject>(token) : null);

export const getUserFromAccessToken = (accessToken: string | null) => {
  const decodedToken = decodeJwtToken(accessToken);
  return new User({ ...decodedToken, id: decodedToken?.userId });
};

const setStates = (response: AxiosResponse<any, any>, setAuthToken: (accessToken: string | null) => void, setAccessToken: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User | null>>) => {
  const accessToken: string = response.data.accessToken;
  const user = new User({ ...response.data, id: response.data.id });
  const decodedToken = decodeJwtToken(accessToken);
  const expMS = decodedToken ? new Date(0).setUTCSeconds(decodedToken.exp) : null;
  let ttl: number | undefined = undefined;
  if (expMS) {
    ttl = expMS - Date.now();
  }

  setLocalStorage("accessToken", accessToken, ttl);
  setLocalStorage("user", user, ttl);
  setAuthToken(accessToken);
  setAccessToken(accessToken);
  setUser(user);
};

export const findRole = (roles: Role[] | undefined, name: string): Role | undefined => {
  if (roles) {
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].name === name) {
        return roles[i];
      }
      let found = findRole(roles[i].childRoles, name);
      if (found) return found;
    }
  }
};

export const userHasRole = (user: User, roleName: string): boolean => (user.role && findRole([user.role], roleName) ? true : false);
export const isUserSuperAdmin = (user: User): boolean => userHasRole(user, "Super Admin");
export const isUserAdmin = (user: User): boolean => userHasRole(user, "Admin");
export const isUserUser = (user: User): boolean => userHasRole(user, "User");
