import React, { useState, PropsWithChildren } from "react";
import { getLocalStorage } from "Helpers";
import { UserContext } from "Contexts";
import { User } from "Models";

export const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(getLocalStorage<string>("accessToken"));
  const [user, setUser] = useState<User | null>(getLocalStorage("user"));

  return <UserContext.Provider value={{ accessToken, setAccessToken, user, setUser }}>{children}</UserContext.Provider>;
};
