import { User } from "Models";
import { UserService } from "Services";
import React, { Dispatch, SetStateAction } from "react";

export const useUsers = (): [User[], Dispatch<SetStateAction<User[]>>, boolean] => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    UserService.getAll()
      .then((users) => setUsers(users))
      .finally(() => setIsLoading(false));
  }, []);

  return [users, setUsers, isLoading];
};
