import { PropsWithChildren, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getLocalStorage } from "Helpers";
import { UserContext } from "Contexts";

interface ProtectedRouteProps extends PropsWithChildren {
  redirectPath?: string;
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectPath = "/login" }) => {
  const { pathname } = useLocation();
  const isUserLoggedIn = getLocalStorage<string>("accessToken") ? true : false;
  const { setAccessToken, setUser } = useContext(UserContext);

  if (!isUserLoggedIn) {
    setAccessToken(null);
    setUser(null);

    return <Navigate to={`${redirectPath}?redirectTo=${encodeURIComponent(pathname)}`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
