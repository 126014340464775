import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

export const ConvertToBase64: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [base64, setBase64] = useState<string | undefined>(undefined);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        //file["base64"] = result;
        setBase64(result as string);
      })
      .catch((err) => {
        console.error(err);
      });

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(file);
  };

  return (
    <Box>
      <input type="file" onChange={(e) => onSelectFile(e)} />
      {base64}
      {selectedFile && <img src={base64} alt="sd" />}
      {selectedFile && <img src={preview} alt="sd" />}
    </Box>
  );
};

export default ConvertToBase64;
