import axios from "axios";
import { Application } from "Models";

export class ApplicationService {
  static async getAll(): Promise<Application[]> {
    const response = await axios.get<Application[]>("/api/Application");
    return response.data;
  }

  static async insert(application: Application): Promise<Application> {
    const response = await axios.post<Application>("/api/Application", application);
    return response.data;
  }
}
