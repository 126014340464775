import { useState } from "react";
import { Grid, TextField, Button, Stack, Autocomplete, Typography } from "@mui/material";
import { useApplicationTypes } from "Hooks";
import { ApplicationService } from "Services";
import { Application, ApplicationType } from "Models";

const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

export const AddApplication: React.FC = () => {
  //const [user, , isLoading] = useContext(UserContext);
  const [newApp, setNewApp] = useState<Application>(new Application());
  const [selectedApplicationType, setSelectedApplicationType] = useState<ApplicationType>(new ApplicationType());
  const [applicationTypes] = useApplicationTypes();

  const handleOnChangeTextField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewApp((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnChangeImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    getBase64(file)
      .then((icon) => {
        setNewApp((prevState) => ({ ...prevState, icon }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOnClickCreateNewApplication = () => {
    ApplicationService.insert(newApp)
      .then((application) => {
        setNewApp(new Application());
        setSelectedApplicationType(new ApplicationType());
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid container>
      <Grid xs={0} sm={1} md={2} lg={3} xl={3} item />
      <Grid xs={12} sm={10} md={8} lg={6} xl={6} item>
        <Stack spacing={2} p={2}>
          <Typography variant="body2" color="GrayText" align="center">
            Add new Application
          </Typography>
          <TextField onChange={handleOnChangeTextField} size="small" placeholder="Name" name="name" value={newApp.name} />
          <TextField onChange={handleOnChangeTextField} size="small" placeholder="Description" name="description" value={newApp.description} />
          <TextField onChange={handleOnChangeTextField} size="small" placeholder="URL" name="url" value={newApp.url} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <input type="file" onChange={handleOnChangeImageUpload} />
            {newApp.icon && <img src={newApp.icon} alt="sd" height={30} />}
          </Stack>
          <Autocomplete
            options={applicationTypes}
            renderInput={(props) => <TextField {...props} size="small" placeholder="Application Type" />}
            getOptionLabel={(option) => option.name}
            value={selectedApplicationType.id ? selectedApplicationType : null}
            onChange={(e, option) => {
              setSelectedApplicationType(option ?? new ApplicationType());
              setNewApp((prevState) => ({
                ...prevState,
                applicationTypeId: option?.id ? option.id : 0,
              }));
            }}
          />
          <Button onClick={handleOnClickCreateNewApplication} variant="contained" disabled={!newApp.applicationTypeId || !newApp.description || !newApp.icon || !newApp.name || !newApp.url}>
            Create
          </Button>
        </Stack>
      </Grid>
      <Grid xs={0} sm={1} md={2} lg={3} xl={3} item />
    </Grid>
  );
};
