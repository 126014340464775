import { Base } from './Base.model';

export class Application extends Base {
    public description: string;
    public name: string;
    public url: string;
    public icon: string;
    public applicationTypeId: number;
    public isActive: boolean;

    constructor(other?: Partial<Application>) {
        super(other);

        this.description = other?.description ?? "";
        this.name = other?.name ?? "";
        this.url = other?.url ?? "";
        this.icon = other?.icon ?? "";
        this.applicationTypeId = other?.applicationTypeId ?? 0;
        this.isActive = other?.isActive ?? true;
    }
}