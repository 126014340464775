import { Role } from "./";
import { Base } from "./Base.model";

export class User extends Base {
  public username: string;
  public email: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public roleId: number;
  public userProfileImage: string;
  public role?: Role;

  constructor(other?: Partial<User>) {
    super(other);

    this.username = other?.username ?? "";
    this.email = other?.email ?? "";
    this.password = other?.password ?? "";
    this.firstName = other?.firstName ?? "";
    this.lastName = other?.lastName ?? "";
    this.roleId = other?.roleId ?? 0;
    this.userProfileImage = other?.userProfileImage ?? "";
    this.role = other?.role;
  }
}
