import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "Contexts";
import { isUserSuperAdmin } from "Middleware";

export const SuperAdminRoute: React.FC = () => {
  const { user } = useContext(UserContext);

  const isSuperAdmin = user && isUserSuperAdmin(user);

  if (!isSuperAdmin) {
    return <Navigate to={"/"} replace />;
  }

  return <Outlet />;
};

export default SuperAdminRoute;
