import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppBar, Box, /*Button,*/ Toolbar, IconButton, Typography, Menu, MenuItem, Container, Avatar } from "@mui/material";
import { /*Menu as MenuIcon,*/ Home as HomeIcon } from "@mui/icons-material";
import { isUserSuperAdmin, logout } from "Middleware";
import { UserContext } from "Contexts";
import { getLocalStorage, setAuthToken } from "Helpers";
import LoginButton from "./LoginButton";

//const pages = ['Personal Apps', 'Simple', 'Techonov'];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export const Header: React.FC = () => {
  const accessToken = getLocalStorage("accessToken") ?? undefined;
  const { setAccessToken, user, setUser } = useContext(UserContext);
  const isSuperAdmin = user && isUserSuperAdmin(user);
  const Navigate = useNavigate();

  //const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  /*const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };*/
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  /*const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };*/

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout(setAuthToken, setAccessToken, setUser);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <HomeIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            onClick={() => Navigate("/")}
            variant="h6"
            noWrap
            sx={{
              cursor: "pointer",
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Brian Prive
          </Typography>
          <HomeIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Brian Prive
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0 }}>
            {accessToken ? (
              <>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user?.firstName} src={user?.userProfileImage} />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  MenuListProps={{ sx: { minWidth: 300 } }}
                  closeAfterTransition
                  onClick={handleCloseUserMenu}
                >
                  {isSuperAdmin ? (
                    <MenuItem onClick={() => Navigate("/settings")}>
                      <Typography textAlign="center">Settings</Typography>
                    </MenuItem>
                  ) : null}

                  {accessToken ? (
                    <MenuItem onClick={handleLogout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  ) : null}
                </Menu>
              </>
            ) : (
              <LoginButton />
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
