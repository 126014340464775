import { Base } from './Base.model';
import { Application } from './';

export class ApplicationType extends Base {
    public name: string;
    public icon: string;

    public applications: Application[];

    constructor(other?: Partial<ApplicationType>) {
        super(other);

        this.name = other?.name ?? "";
        this.icon = other?.icon ?? "";
        this.applications = other?.applications ?? [];
    }
}